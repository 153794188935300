// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const StepsBlock = ({ title, steps }) => (
	<div data-rellax-speed="-2.25" className="manifest__section-side manifest__section-side--small manifest__section-side--highlight">
        <h1 className="manifest__heading">No Valeurs.</h1>
        <ul className="manifest__value-list">
            {
                steps.map((step, index) => (
                    <li key={index} className="manifest__value-list-item">{step}</li>
                ))
            }
        </ul>
    </div>
)

StepsBlock.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
}

StepsBlock.defaultProps = {
  title: ``,
  steps: [],
}

export default StepsBlock
