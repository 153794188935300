// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import VideoCover from "./video-cover"
import ImageCover from "./image-cover"

const LastRealisation = () => (
	<React.Fragment>
        
        { /* <section className="scroll-section"> */}
            <VideoCover
                title="Nos dernières réalisations" 
                videoUrl="/video/test.mp4" 
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="My SODEXO MAROC."
                content="Grâce à son module de géolocalisation, l’application mobile de Sodexo vous permet de localiser rapidement sur carte les commerces autour de vous afin de connaitre les lieux d’utilisation de vos Mida et Cado Pass."
                image="/img/hero/sodexo.jpg"
                btn={{ 
                    value: "En savoir plus",
                    action: "/projets/sodexo"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="LA ROUE DE LA FORTUNE."
                content={ `
                * Un Bon Cadeau est envoyé par E-mail au gagnant \n
                * Le "Bon Cadeau" est valable dans tous les magasins COSMOS. \n
                * Le bon est valable une seule fois. \n
                * Il faut présenter impérativement le bon "Imprimé" ou "Téléchargé" pour bénéficier du gain. \n
                `}
                image="/img/hero/larouedelafortune.jpg"
                btn={{ 
                value: "En savoir plus",
                action: "/projets/cosmos"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="TACOS DE LYON."
                content="Au delà d’un sandwich préparé sous vos yeux, Tacos de Lyon vous propose de créer vous-mêmes vos CHEFS D’ŒUVRES en ligne…"
                image="/img/hero/tacosdelyon.jpg"
                btn={{ 
                value: "En savoir plus",
                action: "/projets/tacos-de-lyon"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="PRECISION SYSTEMS."
                content="Centralisez sur un seul système les commandes en ligne, la commande et l'encaissement sur place ainsi que la gestion de stock de vos magasins et centrales d'achat. Tous les processus sont connectés, avec des interfaces personnalisées et des privilèges adaptées à chaque poste."
                image="/img/hero/precisionsystems.jpg"
                btn={{ 
                value: "En savoir plus",
                action: "/projets/precision-systems"
                }}
            />
        {/* </section> */}
    </React.Fragment>
)

export default LastRealisation
