import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const CoverDetail = ({ title, content, image, btn }) => (
	<div 
		style={{ "backgroundImage": `url(${image})` }}
		className="scroll-jacker__cassette scroll-jacker__cassette--active hero inuit-box inuit-box--flush"
	>
		<div className="hero__side-box">
				<h2 className="hero__message hero__message--big">{ title }</h2>
				<p className="hero__message hero__message--smaller">
						{ content }
				</p>
				<p className="call-to-action hero__message">
					{
						btn ? (
							<Link to={ btn.action } className="call-to-action__link" title={ btn.value }>
									{ btn.value }
							</Link>
						) : (<></>)
					}
				</p>
		</div>
		<button data-wall-slide-arrow type="button" className="hero__chevron-south inuit-btn"></button>
	</div>
)

CoverDetail.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  btn: PropTypes.object,
}

CoverDetail.defaultProps = {
  title: ``,
  content: ``,
  image: ``,
  btn: null
}

export default CoverDetail
