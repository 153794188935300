// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import StepsBlock from "./steps-block"
import MethodologySection from "./methodology-section"
import NewsletterForm from "./newsletter-form"
import Footer from "./footer"

const WhoAreWe = () => (
    <div>
        <section className="manifest__section manifest__section--expanded manifest__section--dark manifest__section--values">
            <div className="manifest__section-container">
                <StepsBlock title="Nos Valeurs." steps={ [ "Innovation" , "Passion" , "Gratitude et humilité" , "Simplicité" ,
                    "Responsabilité, dignité et confiance" , "Travail en équipe" ] } />
                <div className="manifest__section-side manifest__section-side--big">
                    <h1 className="manifest__heading">Qui sommes nous.</h1>
                    <p className="manifest__para manifest__para--invert">
                        Depuis 2014, Utopie accompagne avec succès de prestigieuses entreprises dans la création, maintenance
                        et gestion de leurs portefeuille d'applications.
                        <br />
                        Propulsé par une équipe de passionnés, UTOPIE compte dans son portefeuille des clients de divers
                        tailles et bords.
                        <br />
                        La maîtrise du marché local combinée à l’expérience de ses partenaires internationaux permet à UTOPIE
                        de proposer des dispositifs aux dimensions de vos attentes.
                    </p>
                </div>
            </div>
        </section>

        <MethodologySection title="Comment nous nous y prenons." content={` Comme de véritables pionniers, nous ne suivons pas
            un chemin simplement parce que c'est facile. L'efficacité et l'efficience sont des aspects clés de nos approches de
            projets. Quelle que soit la tâche à accomplir, cela peut se faire. `} steps={ [ "Listen" , "Propose" ,
            "Architecture" , "User Experience" , "Devops" , "Develop, test and deploy" ] } />

        <NewsletterForm />

        <Footer />
    </div>
)

export default WhoAreWe
