import React from "react"

class NewsletterForm extends React.Component {
  render = () => (
    <form action="#" method="post" className="newsletter-form">
        <fieldset className="newsletter-form__fieldset">
            <legend className="newsletter-form__legend">Restez informés</legend>
            <input name="email" type="email" className="newsletter-form__input inuit-u-p-" placeholder="Addresse Email" required />
            <button type="submit" className="newsletter-form__submit inuit-btn">S’inscrire</button>
        </fieldset>
    </form>
  )

  componentDidMount = () => {
    // helpers.initPagePilling()
  }

}

export default NewsletterForm
