import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import ImageCover from "../components/image-cover"
import Realisation from "../components/realisation"
import LastRealisation from "../components/last-realisation"
import SEO from "../components/seo"
// import helpers from "../scripts/helpers"

// import cssStyle from "../styles/pagepiling.css"

class IndexPage extends React.Component {

  render = () => (
    
      <Layout>
      
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        
        <main id="wall">

          { /* <section className="scroll-section"> */}
            <VideoCover
              title="Conseil, technologie et Transformation digitale"
              videoUrl="/video/test.mp4" 
            />
          {/* </section> */}

          
          { /* <section className="scroll-section"> */}
            <ImageCover 
              title="Transformation digitale et innovation"
              content="L'Industrie 4.0, le Cloud, la mobilité, le temps réel, l’internet des objets, le big data et l’universalité d’internet redessinent les contours de l'entreprise et sa capacité à innover. UTOPIE vous accompagne dans une transformation profonde et accélérée."
              image="/img/hero/transformationinnovation.jpg"
              btn={{ 
                value: "En savoir plus",
                action: "/transformation-innovation"
              }}
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Développement, maintenance & infra"
              content="Nous concevons, transformons et optimisons comment vos serveurs & applications raisonnent et échangent avec d'autres serveurs, clients légers, applets et applications mobiles. Votre portefeuille d'applications au cœur de nos préoccupations !"
              image="/img/hero/dev.jpg"
              btn={{ 
                value: "En savoir plus",
                action: "/developpement"
              }}
            />
          {/* </section> */}

          <LastRealisation />

          <Realisation />

        </main>
      </Layout>
    
  )

  constructor(props) {
    super(props);
    this.state = {
      wall: null
    }
  }

  componentDidMount = () => {
    // helpers.loadCSS(cssStyle, "#pagepilling")
  }

  componentWillUnmount = () => {
    // helpers.unloadCSS("#pagepilling")
  }

}

export default IndexPage
