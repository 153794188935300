// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Sponsores = () => (
    <blockquote className="quote quote--small">

        <ul className="manifest__certification-list">
            
            <li
                title="Google Search Advertising"
                className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
            />

            <li
                title="Google Video Advertising"
                className="manifest__certification-list-item manifest__certification-list-item--google-video-advertising"
            />

            <li
                title="Google Display Advertising"
                className="manifest__certification-list-item manifest__certification-list-item--google-display-advertising"
            />

            <li
                title="Google Shopping Advertising"
                className="manifest__certification-list-item manifest__certification-list-item--google-shopping-advertising"
            />

        </ul>
        
    </blockquote>
)

export default Sponsores
