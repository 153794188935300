// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Sponsores from "./sponsores"
import WhoAreWe from "./who-are-we"
import Footer from "./footer"
import ProjectCube from "./project-cube"

const Realisation = () => (
	<div className="scroll-jacker__cassette pp-scrollable" style={{ backgroundColor: "rgba(35, 35, 35, 0.96)" }}>
        <ul className="gallery social-feed-list">
        
        <ProjectCube
            title=" Tacos de Lyon <br /> Hybrid App, Web Developpement, Dev Android & Ios"
            action="/projets/tacos-de-lyon"
            image="/img/posts/tacosdelyon.jpg"
        />

        <ProjectCube
            title="Bosch B/S/H <br /> Riche Media, Digital, Vidéo<br /> Goodies et Print"
            action="/projets/bosch-B-S-H"
            image="/img/posts/bosch-home.jpg"
        />

        <ProjectCube
            title=" Collège Sherbrooke <br /> WebApp, Web Developpement, Riche Media & Digital"
            action="/projets/sherbrooke"
            image="/img/posts/sherbrooke.jpg"
        />

        <ProjectCube
            title="BOSCH - BCS <br /> Conception, Print, Display, Goodies, Digital, Spot Radio, Event & Vidéos"
            action="/projets/bosch"
            image="/img/posts/bosch.jpg"
        />

        <ProjectCube
            title="SODEXO <br /> Webdesign, Developpement web & mobile"
            action="/projets/sodexo"
            image="/img/posts/sodexo.jpg"
        />

        <ProjectCube
            title="COSMOS ELECTRO <br /> Webdesign, Developpement web & Digital Marketing"
            action="/projets/cosmos"
            image="/img/posts/cosmos.jpg"
        />

        </ul>

        <Sponsores />

        <br />

        <WhoAreWe />

    </div>
)

export default Realisation
